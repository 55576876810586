

class CMCatfishAD extends HTMLElement {

    $catfishCnt;
	$amCreative;
    $lnkClose;

	constructor() {
        super();

		this.$catfishCnt = this.querySelector('.catfishCnt');
		this.$amCreative = this.querySelector('.amCreative');
		this.$lnkClose = this.querySelector('.lnkCatFishClose');
		this.$content = this.querySelector('.amCreative .content');		

		window.showCatfish = (opts) => {

			if (this.$amCreative) {

				this.$catfishCnt.style.display = 'flex';
	
				setTimeout( () => {
					this.$content.style.width = `${opts.size[0]}px`;
					this.$content.style.maxHeight = `${opts.size[1]}px`;
				}, 50);
			}
	
			if (this.$lnkClose) {
				setTimeout( () => {
					this.$lnkClose.style.display = 'unset';
				}, 4500)
			}
		}

		this.$lnkClose?.addEventListener('click', this.onClose);
	}

	onClose = e => {
		e.preventDefault();
		if (this.$amCreative) {
			this.$amCreative.innerHTML = '';
			this.$amCreative.style.maxHeight = '';
			this.$lnkClose.style.display = 'none';
		}
	}
	
	disconnectedCallback() {
		this.$lnkClose?.removeEventListener('click', this.onClose);
    }
}

if (!customElements.get("cy-catfish"))
	customElements.define("cy-catfish", CMCatfishAD);