import Marquee3k from 'marquee3000';

class BreakingNews extends HTMLElement {

	$marquee = null;

	constructor() {
        super();

		this.$marquee = document.querySelector('.breakingNews');

		if (this.$marquee) {
			Marquee3k.init({
				selector: 'breakingNews'
			});
		}
	}

	disconnectedCallback() {
		
 	}
}

if (!customElements.get("breaking-news"))
	customElements.define("breaking-news", BreakingNews);