

class CMTakeOver extends HTMLElement {

	$amCreative;
    $lnkClose;

	constructor() {
        super();

		this.$cnt = this.querySelector('.takeOverCnt');
		this.$amCreative = this.querySelector('.amCreative');
		this.$lnkClose = this.querySelector('.lnkTakeoverClose');		

		window.showTakeOver = (opts) => {

			if (this.$cnt) {
				setTimeout( () => {
					this.$amCreative.style.width = `${opts.size[0]}px`;
					this.$amCreative.style.height = `${opts.size[1]}px`;
					this.$cnt.classList.add('visible');
				}, 50);
			}
	
			if (this.$lnkClose) {
				setTimeout( () => {
					this.$lnkClose.style.display = 'unset';
				}, 4500)
			}
		}

		this.$lnkClose?.addEventListener('click', this.onClose);
	}

	onClose = e => {
		e.preventDefault();
		if (this.$cnt) {
			this.$lnkClose.style.display = 'none';
			this.$amCreative.style.height = '';
			this.$amCreative.style.width = '';
			this.$cnt.classList.remove('visible');
		}
	}
	
	disconnectedCallback() {
		this.$lnkClose?.removeEventListener('click', this.onClose);
    }
}

if (!customElements.get("cy-takeover"))
	customElements.define("cy-takeover", CMTakeOver);