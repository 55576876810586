import AdManager from './aManager.js';
import { areConsentsAccepted, enableScriptForConsents} from "./scriptsManager.js"

document.body.classList.add('untouched');

function loadCMP() {
	return import('./cmp.js');
}

function onCmpChanged(e) {

	const $lnkCmp = document.getElementById('lnkCMP');
	$lnkCmp?.addEventListener('click', onPrivacyClicked);

	const consentsGiven = e?.detail ?? {};

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		enableScriptForConsents('google');
		enableScriptForConsents('sentry');
	}

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		window.AdManager = AdManager;
		AdManager.init();
		AdManager.displayAll();
	}

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		enableScriptForConsents('onesignal')
	}

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		setTimeout( () => {
			enableScriptForConsents('projectagora')
		}, 2500);
	}

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		setTimeout( () => {
			enableScriptForConsents('echobox')
		}, 4500);
	}

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		setTimeout( () => {
			enableScriptForConsents('clarity')
		}, 1500);
	}

	if (areConsentsAccepted(consentsGiven, [1, 2, 7, 9, 10])) {
		//enable iframes
		setTimeout( () => {
			const iFrames = document.querySelectorAll("iframe[data-cmp-src]");
			iFrames.forEach( IF => {
				IF.setAttribute('src', IF.getAttribute('data-cmp-src'));
			})
		}, 2000);
		
	}

	
}

function onPrivacyClicked(e) {
	e.preventDefault();
	try {
		__tcfapi('displayConsentUi', 2, () => { })
	} catch(err) {

	}
}

window.addEventListener('cmpChanged', onCmpChanged);

document.addEventListener('astro:page-load', () => {
	requestIdleCallback( () => {
		if (!window.location.search.includes("isMobileApp=true"))
			loadCMP().then(r => r.CMP && r.CMP())
   })

   initScrollSpy();
})

document.addEventListener('astro:before-swap', ev => {
	AdManager.destroyAll();
});

document.addEventListener('astro:after-swap', ev => {
	
});

document.addEventListener('astro:before-preparation', () => {
	const $lnkCmp = document.getElementById('lnkCMP');
	$lnkCmp?.removeEventListener('click', onPrivacyClicked);
	destroScrollSpy();
	
})



//set touched
window.addEventListener('scroll', onUserInteracted);
window.addEventListener('mousedown', onUserInteracted);
window.addEventListener('touchstart', onUserInteracted);

function onUserInteracted(e) {
	setTimeout( () => {
		document.body.classList.remove('untouched');
	}, 1);
	window.removeEventListener('scroll', onUserInteracted);
	window.removeEventListener('mousedown', onUserInteracted);
}

//--scrollspy--
function initScrollSpy() {
	const $scrollDiv = document.getElementById('scrollpos')
	if (!$scrollDiv)
		return;

	const onIntersect = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				document.body.classList.remove('stuck');
			} else {
				document.body.classList.add('stuck');
			}
		})
	}

	const observer = new IntersectionObserver(onIntersect, {
		rootMargin: "10px"
	})
	observer.observe($scrollDiv)
}

function destroScrollSpy() {

}

