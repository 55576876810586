document.addEventListener("astro:page-load", () => {
   document.getElementById('header-search-open-menu').addEventListener('keydown', function (e) {
      if (e.key === 'Enter') {
         const value = e.target.value;
         if (e.length === 0) return;
         window.location.href = `/search?q=${value}`;
      }
   });

   document.getElementById("central-input-search-cta").addEventListener('click', function (e) {
      const value = document.getElementById('search').value;
      if (value.length === 0) return;
      window.location.href = `/search?q=${value}`;
   })

})